.app{
    // background-color: black;
    height: 100%;
}
.symbols{
    display: flex;
    z-index: 10000;
    gap: 20px;
}
.symbol{
    width: 75px;
    height: 75px;
    background-color: #222;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30px;
    border-radius: 1rem;
    cursor: pointer;
    border: 2px solid white;
    &.dropzone{
        border: 2px dotted white;
        width: 100px;
        height: 100px;
        position: relative;
        overflow: hidden;
    }
}

.droppable{
    display: flex;
    gap: 20px;
}

.container{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.background-text{
    position: absolute;
    text-transform: uppercase;
    opacity: 0.1;
    font-size: 120px;
    font-weight: bold;
    transform: translateY(-5px);
}

.image{
    border-radius: 100%;
    width: 75%;
}

.result{
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}